// @flow
import { handleActions } from "redux-actions";

import { CALL_API } from "redux/middleware/api";
import type { callApiReturnType } from "redux/middleware/api";
import type {
  simpleReduxAction,
  apiErrorReturn,
  apiErrorParams,
  apiErrorAction,
} from "shared/constants/flowTypes";
import { APP_NAMESPACE } from "shared/constants/application";
import { NONE, LOADING, LOADED, ERROR } from "shared/constants/status";

// actions
const namespace: string = `${APP_NAMESPACE}/retoolEventCheckIn`;
export const EMBED_REQUEST: string = `${namespace}/FETCH/REQUEST`;
export const EMBED_REQUEST_SUCCESS: string = `${namespace}/FETCH/SUCCESS`;
export const EMBED_REQUEST_ERROR: string = `${namespace}/FETCH/ERROR`;
export const CLEAR: string = `${namespace}/CLEAR`;

// action creators
export const requestEmbedURL = (): simpleReduxAction => ({
  type: EMBED_REQUEST,
});

export const receiveEmbedURL = (apiResponse: { embedUrl: string }): string =>
  typeof apiResponse.embedUrl === "string"
    ? {
        type: EMBED_REQUEST_SUCCESS,
        payload: apiResponse,
      }
    : {
        type: EMBED_REQUEST_ERROR,
        error: true,
        payload: {
          errorMessage: undefined,
          errors: [],
        },
        meta: {
          receivedAt: Date.now(),
        },
      };

export const embedRequestError = (error: apiErrorParams): apiErrorAction => ({
  type: EMBED_REQUEST_ERROR,
  error: true,
  payload: {
    errorMessage: "Error message",
    errors: [
      {
        detail: "Error - please try again later...",
      },
    ],
  },
  meta: {
    receivedAt: Date.now(),
  },
});

export const clear = (): simpleReduxAction => ({
  type: CLEAR,
});

// Async actions
export const getRetoolEmbedUrl = (): callApiReturnType => ({
  type: CALL_API,
  payload: {
    method: "GET",
    endpoint: "/retool/instructor-events",
    actions: {
      request: requestEmbedURL,
      success: receiveEmbedURL,
      failure: embedRequestError,
    },
  },
});

// Initial State
export type State = {
  status: string,
  errorMessage: string,
  errors: apiErrorReturn,
  receivedAt: number,
  embedURL: string,
};

const initialState: State = {
  status: NONE,
  errorMessage: "",
  errors: [],
  receivedAt: 0,
  embedURL: "",
};

const retoolEventCheckIn = handleActions(
  {
    [EMBED_REQUEST]: (state: State): State => ({
      ...state,
      status: LOADING,
      errorMessage: "",
      errors: [],
      embedURL: "",
    }),
    [EMBED_REQUEST_SUCCESS]: (
      state: State,
      action: { payload: { embedUrl: string } }
    ): State => {
      const {
        payload: { embedUrl },
      } = action;
      return {
        ...state,
        status: LOADED,
        embedURL: embedUrl,
      };
    },
    [EMBED_REQUEST_ERROR]: (state: State, action: apiErrorAction): State => {
      const {
        payload: { errorMessage, errors },
      } = action;
      return {
        ...state,
        status: ERROR,
        errorMessage,
        errors,
        embedURL: "",
      };
    },
    [CLEAR]: (state: State, action: simpleReduxAction): State => initialState,
  },
  initialState
);

export default retoolEventCheckIn;
